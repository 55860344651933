import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InstallService } from '../../../../services/install.service';
import { IInstall, IService } from '../../../../services/types';

@Component({
	selector: 'app-pppoe-details',
	templateUrl: './pppoe-details.component.html',
	styleUrls: ['./pppoe-details.component.scss'],
})
export class PppoeDetailsComponent implements OnInit {

	@Input() public readonly install: IInstall;

	public service: Partial<IService> = {};

	public wifiUsername: string;
	public wifiPassword: string;
	public isEditing = false;
	private readonly minPasswordLength = 8;
	private readonly maxPasswordLength = 32;
	validPassword = true;

	constructor(
		public installService: InstallService,
		private readonly toast: ToastrService,
	) { }

	public ngOnInit(): void {
		this.refreshPppoe();
	}

	public copyToClipboard(clipboardText: string): void {
		navigator.clipboard.writeText(clipboardText).then(() => this.toast.success('Copied to Clipboard'));
	}

	public refreshPppoe(): void {
		this.installService.getService(this.install?.account_id).subscribe(a => {
			this.service = a;
			this.wifiUsername = this.service.wifi_username;
			this.wifiPassword = this.service.wifi_password;
		});
	}

	public saveWifi(): void {
			this.installService.updateAccount(this.install.account_id, {
				wifi_username: this.service.wifi_username,
				wifi_password: this.service.wifi_password,
			}).subscribe(() => {
				this.toast.success('Wi-Fi Details Saved!');
			});
			this.updateEditStatus();
			this.refreshPppoe();

	}

	public updateEditStatus(): void {
		this.isEditing = !this.isEditing;
	}

	onPasswordChange() {
		this.validPassword = this.validPasswordCheck();
	}

	validPasswordCheck(): boolean {
		return this.service.wifi_password.length >= this.minPasswordLength && this.service.wifi_password.length <= this.maxPasswordLength;
	}
}
