<div class="install-client mat-elevation-z2" fxLayout="column" fxLayoutGap=".5rem">
	<div class="client-info">
		<div class="client-header" fxLayout fxLayoutAlign="space-between center">
			<div>PPPoE Information</div>
		</div>
		<h4>PPPoE Username</h4>
		<div>{{ service?.pppoe_username || 'None' }}&nbsp;<fa-icon icon="copy" size="lg" (click)="copyToClipboard(service.pppoe_username)"></fa-icon></div>
		<h4>PPPoE Password</h4>
		<div>{{ service?.pppoe_password || 'None' }}&nbsp;<fa-icon icon="copy" size="lg" (click)="copyToClipboard(service.pppoe_password)"></fa-icon></div>
		<br>

		<div class="client-header" fxLayout fxLayoutAlign="space-between center">
			<div>Wi-Fi Information</div>
		</div>
        <div *ngIf="service">
            <h4>Wi-Fi Username</h4>
            <input [(ngModel)]="service.wifi_username">
            <h4>Wi-Fi Password</h4>
            <input [(ngModel)]="service.wifi_password" (ngModelChange)="onPasswordChange()">
        </div>
        <div *ngIf="!validPassword" class="alert alert-danger">
            Password to be between 8 to 32 characters (Alphanumeric and Special Characters are allowed)
        </div>
		<br>
        <div style="display: flex; justify-content: space-between;">
            <button mat-flat-button color="primary" type="button" class="icon-inside" (click)="refreshPppoe()">
                <fa-icon icon="sync-alt"></fa-icon>
                <span>Refresh</span>
            </button>
            <button mat-flat-button color="primary" type="button" class="icon-inside" [disabled]="!validPassword" (click)="saveWifi()">
                <fa-icon icon="wifi"></fa-icon>
                <span>Save WiFi Details</span>
            </button>
        </div>
	</div>
</div>
