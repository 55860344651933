<div fxLayout="column" fxLayoutGap="1rem" class="detail-container">

	<button mat-flat-button color="primary" class="icon-inside" (click)="goBack()" fxFlexAlign="start" *ngIf="isOnChildRoute; else backToInstalls">
		<fa-icon icon="arrow-left"></fa-icon>
		<span>Back to {{ install.type_id | installType }}</span>
	</button>

	<ng-template #backToInstalls>
		<button mat-flat-button color="primary" class="icon-inside" (click)="goBackToInstalls()" fxFlexAlign="start">
			<fa-icon icon="arrow-left"></fa-icon>
			<span>Back to My Installs</span>
		</button>
	</ng-template>

	<!-- Install Actions -->
	<div class="install-action mat-elevation-z2" *ngIf="anyAction && !isOnChildRoute">
		<div fxLayout="row wrap" fxLayoutGap="1rem grid" fxLayoutAlign="center">
			<div fxFlex="25%" fxFlex.xs="60%" fxFlex.sm="33%" fxFlex.md="33%" *ngIf="installActions.begin">
				<button matTooltip="Start {{ install.type_id | installType }}"
						mat-flat-button
						color="primary"
						class="icon-inside green"
						[disabled]="!canEdit"
						(click)="startInstall()"
						fxFlex="1 1 auto">
					<fa-icon icon="play-circle"></fa-icon>
					<span>{{ beginBtnCaption }}</span>
				</button>
			</div>
			<div fxFlex="25%" fxFlex.xs="60%" fxFlex.sm="33%" fxFlex.md="33%" *ngIf="installActions.reassign">
				<button matTooltip="Re-Assign {{ install.type_id | installType }}"
						mat-flat-button
                        color="primary"
                        class="icon-inside green"
                        [disabled]="!canEdit"
                        (click)="navigateTo('reassign')"
                        fxFlex="1 1 auto">
                    <fa-icon [icon]="['fas', 'retweet']"></fa-icon>
					<span>Re-Assign</span>
                </button>
            </div>
			<div fxFlex="25%" fxFlex.xs="60%" fxFlex.sm="33%" fxFlex.md="33%" *ngIf="installActions.reschedule">
				<button matTooltip="Reschedule {{ install.type_id | installType }}"
						mat-flat-button
						color="primary"
						class="icon-inside orange"
						(click)="navigateTo('reschedule')"
						fxFlex="1 1 auto">
					<fa-icon [icon]="['far', 'clock']"></fa-icon>
					<span>Reschedule</span>
				</button>
			</div>
			<div fxFlex="25%" fxFlex.xs="60%" fxFlex.sm="33%" fxFlex.md="33%" *ngIf="installActions.cancel">
				<button matTooltip="Cancel {{ install.type_id | installType }}"
						mat-flat-button
						color="primary"
						class="icon-inside red"
						(click)="navigateTo('cancel')"
						fxFlex="1 1 auto">
					<fa-icon icon="times-circle"></fa-icon>
					<span>Cancel</span>
				</button>
			</div>
			<div fxFlex="25%" fxFlex.xs="60%" fxFlex.sm="33%" fxFlex.md="33%" *ngIf="installActions.hold">
				<button matTooltip="Place {{ install.type_id | installType }} On Hold" mat-flat-button color="primary" class="icon-inside blue"
						(click)="navigateTo('hold')"
						fxFlex="1 1 auto">
					<fa-icon icon="pause-circle"></fa-icon>
					<span>Place On Hold</span>
				</button>
			</div>
		</div>

		<div class="alert alert-danger too-far" fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="space-between center" *ngIf="beginBtnCaption === 'Begin' && !canEdit">
			<span>You are too far away to begin your Install</span>
			<div matTooltip="Refresh Location" (click)="refreshLocation()">
				<fa-icon icon="sync-alt" class="action-icon"></fa-icon>
			</div>
		</div>
	</div>
	<!-- Install Details State Output-->
	<div *ngIf="isOnChildRoute" class="route-output">
		<router-outlet></router-outlet>
	</div>
	<!-- Install Client Detail Card -->
	<mat-tab-group>
		<mat-tab label="Client Details">
			<app-client-details *ngIf="install" [install]="install" ></app-client-details>
		</mat-tab>
        <mat-tab label="Layer 3 Details" *ngIf="layer3Enabled">
			<app-pppoe-details  [install]="install" ></app-pppoe-details>
		</mat-tab>
	</mat-tab-group>
</div>
